import { ThemeProvider, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Typography } from '@mui/material';
import React from 'react';
import _ from '@lodash';
import { Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { frFR } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { gregorian_fr_lowercase } from './config_calendar';
import * as Actions from '../../store/actions';
import 'moment/locale/fr';
import 'dayjs/locale/fr';
import './patient.css';
import { Select } from '@material-ui/core';
// import DatePicker from 'react-multi-date-picker';

const Accordion = styled(props => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0
	},
	'&:before': {
		display: 'none'
	}
}));

const AccordionSummary = styled(props => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)'
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1)
	}
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)'
}));

const PracticianConsultation = ({ control, watch, getValues, setValue }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const routeParams = useParams();

	const formData = useSelector(({ consultationGeneral }) => consultationGeneral.appointment.FormData);

	const AntalgesicTreatment = watch('antalgesicTreatment');
	const [expanded, setExpanded] = React.useState('panel1');
	const [haveTreatment, setHaveTreatment] = React.useState(AntalgesicTreatment);

	React.useEffect(() => {
		if (formData.antalgesicTreatment === 'true') setHaveTreatment('true');
	}, [haveTreatment, formData]);

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	return (
		<ThemeProvider theme={theme}>
			<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-20">
				<div className="w-full">
					<Typography variant="h4" className="text-bold" sx={{ margin: '25px 0' }}>
						Douleurs
					</Typography>
					<div className="w-full grid grid-cols-2 gap-20 items-center justify-center">
						<Controller
							name="dolorLocation"
							control={control}
							render={({ field: { onChange, value } }) => {
								const selectValue = [
									'Cervical',
									'Trapèzes',
									'Dorsal',
									'Lombaire',
									'Sacro-iliaque',
									'Membre supérieur',
									'Membre inférieur'
								];
								return (
									<TextField
										select
										fullWidth
										variant="filled"
										SelectProps={{
											native: true
										}}
										autoFocus
										label="Localisation de la douleur"
										value={value}
										onChange={e =>
											onChange(
												e.target.value,
												dispatch(
													Actions.updateFormData({
														...getValues(),
														dolorLocation: e.target.value
													})
												)
											)
										}
									>
										<option value="null">Non Renseigné</option>
										{selectValue.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
									</TextField>
								);
							}}
							// }}
						/>
						<Controller
							name="eradiation"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									id="eradiation"
									variant="filled"
									className="w-full"
									label="Irradiation de la douleur"
									value={value === null ? '' : value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													eradiation: e.target.value
												})
											)
										)
									}
								/>
							)}
						/>
						<Controller
							name="startEradiationDate"
							className="w-1/2 mb-20"
							control={control}
							locale="fr"
							render={({ field: { onChange, value } }) => {
								const vDate = dayjs(value, 'DD/MM/YYYY') || dayjs(value).format('DD/MM/YYYY');

								return (
									<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
										<DatePicker
											label="Début de la douleur"
											format="DD/MM/YYYY"
											className="w-full"
											value={vDate}
											locale={gregorian_fr_lowercase}
											onChange={newValue => {
												onChange(
													newValue,
													dispatch(
														Actions.updateFormData({
															...getValues(),
															startEradiationDate: dayjs(newValue, 'DD/MM/YYYY')
														})
													)
												);
											}}
											style={{ width: '70%' }}
											slotProps={{ textField: { variant: 'filled' } }}
										/>
									</LocalizationProvider>
								);
							}}
						/>
						<Controller
							name="dolorIntensity"
							control={control}
							render={({ field: { onChange, value } }) => {
								const selectValue = ['1-2', '3-4', '5-6', '7-8', '9-10'];
								return (
									<TextField
										select
										fullWidth
										variant="filled"
										label="Intensité de la douleur"
										autoFocus
										SelectProps={{
											native: true
										}}
										value={value}
										onChange={e =>
											onChange(
												e.target.value,
												dispatch(
													Actions.updateFormData({
														...getValues(),
														dolorIntensity: e.target.value
													})
												)
											)
										}
									>
										<option value="null">Non Renseigné</option>
										{selectValue.map((item, index) => (
											<option key={index} value={item}>
												{item}
											</option>
										))}
									</TextField>
								);
							}}
							// }}
						/>
						<Controller
							name="triggeringFactor"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									id="triggeringFactor"
									className="w-full col-span-2"
									variant="filled"
									label="Facteur déclenchant"
									value={value === null ? '' : value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													triggeringFactor: e.target.value
												})
											)
										)
									}
								/>
							)}
						/>
					</div>
				</div>
				<div className="w-full">
					<Typography variant="h4" className="mx-20 my-20" sx={{ margin: '25px 0' }}>
						Traitement
					</Typography>
					<div className="w-full grid grid-cols-2 gap-20 items-center justify-center">
						<Controller
							name="antalgesicTreatment"
							control={control}
							render={({ field: { onChange, value } }) => {
								return (
									<TextField
										select
										fullWidth
										variant="filled"
										label="Traitement antalgique"
										autoFocus
										SelectProps={{
											native: true
										}}
										value={AntalgesicTreatment}
										onChange={e => {
											setValue('antalgesicTreatment', e.target.value);
											onChange(
												e.target.value,
												setHaveTreatment(e.target.value),
												dispatch(
													Actions.updateFormData({
														...getValues(),
														antalgesicTreatment: e.target.value
													})
												),
												dispatch(
													Actions.updateFormData({
														...getValues(),
														treatmentName: ' ',
														treatmentEfficacy: 'Non renseigné'
													})
												)
											);
											if (e.target.value === false || e.target.value === 'Non Renseigné') {
												
												setValue('treatmentName', ' ');
												setValue('treatmentEfficacy', 'Non renseigné');
											}
										}}
									>
										<option value="Non Renseigné">Non renseigné</option>
										<option value={true}>Oui</option>
										<option value={false}>Non</option>
									</TextField>
								);
							}}
						/>
						<Controller
							name="treatmentName"
							control={control}
							render={({ field: { onChange, value } }) => (
								<TextField
									id="treatmentName"
									required={_.toString(haveTreatment) === 'true'}
									disabled={_.toString(haveTreatment) !== 'true'}
									className="w-full"
									variant="filled"
									label="Lequel"
									value={value === null ? '' : value}
									onChange={e =>
										onChange(
											e.target.value,
											dispatch(
												Actions.updateFormData({
													...getValues(),
													treatmentName: e.target.value
												})
											)
										)
									}
								/>
							)}
						/>
						<Controller
							name="treatmentEfficacy"
							control={control}
							render={({ field: { onChange, value } }) => {
								return (
									<TextField
										select
										fullWidth
										variant="filled"
										label="Efficacité du traitement"
										required={_.toString(haveTreatment) === 'true' ? true : false}
										disabled={_.toString(haveTreatment) !== 'true' ? true : false}
										autoFocus
										SelectProps={{
											native: true
										}}
										value={value}
										onChange={e =>
											onChange(
												e.target.value,
												dispatch(
													Actions.updateFormData({
														...getValues(),
														treatmentEfficacy: e.target.value
													})
												)
											)
										}
									>
										<option value={'Non renseigné'}>Non renseigné</option>
										<option value={'true'}>Oui</option>
										<option value={'false'}>Non</option>
									</TextField>
								);
							}}
						/>
					</div>
				</div>
			</div>
			<Typography variant="h4" className="text-bold" sx={{ margin: '25px 0' }}>
				Préventions
			</Typography>
			<div className="w-full flex flex-col items-center justify-center">
				<FormLabel component="legend">Type de prévention</FormLabel>
				<Controller
					name="prevention1"
					control={control}
					label="Prévention 1"
					render={({ field: { onChange, value } }) => (
						<RadioGroup
							aria-label="gender"
							name="prevention1"
							value={value}
							onChange={e =>
								onChange(
									e.target.value,
									dispatch(
										Actions.updateFormData({
											...getValues(),
											prevention1: e.target.value
										})
									)
								)
							}
							className="flex flex-row justify-around"
						>
							<FormControlLabel value="prevention 1" control={<Radio />} label="Prévention primaire" />
							<FormControlLabel value="prevention 2" control={<Radio />} label="Prévention secondaire" />
							<FormControlLabel value="prevention 3" control={<Radio />} label="Prévention tertiaire" />
						</RadioGroup>
					)}
				/>
			</div>
		</ThemeProvider>
	);
};

export default PracticianConsultation;
