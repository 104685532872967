import React from 'react';
import ConsultationBarHeaderAction from './ConsultationBarHeaderAction';
import ConsultationBarHeaderFullScreen from './ConsultationBarHeaderFullScreen';

export default function ConsultationBarHeader({ setIsFullScreen, isFullScreen, setIsClear, setNeedReset }) {
	return (
		<div
			style={{ backgroundColor: '#243a66' }}
			className="relative w-full items-center flex justify-center text-center h-60 min-h-60"
		>
			<div className="absolute h-full w-full left-5 flex items-center justify-between">
				<ConsultationBarHeaderFullScreen setIsFullScreen={setIsFullScreen} isFullScreen={isFullScreen} />
				<ConsultationBarHeaderAction setIsClear={setIsClear} setNeedReset={setNeedReset} />
			</div>
		</div>
	);
}
