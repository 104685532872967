import QuickPanel from 'app/fuse-layouts/layout4/components/quickPanel/QuickPanel';
import ChatPanel from 'app/fuse-layouts/layout4/components/chatPanel/ChatPanel';
// import { selectChatPanelState } from 'app/fuse-layouts/layout4/components/chatPanel/store/stateSlice';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import _ from '@lodash';

function RightSideLayout4(props) {


  return (
    <> 
      {/* <ChatPanel /> */}

      <QuickPanel />
    </>
  );
}

export default memo(RightSideLayout4);
