import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
	listPracticien: [],
	data: false,
	entity: null,
	open: false,
	idNewPatient: 0,
	openResetConfirmDialog: false
};

const consultReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_PRATICIEN_JOB: {
			return {
				...state,
				listPracticien: action.payload.data
			};
		}
		case Actions.PATIENTS_DIALOG_OPEN: {
			return {
				...state,
				open: action.open
			};
		}
		case Actions.CREATE_CONSULTATION_PATIENT: {
			return {
				...state,
				idNewPatient: action.id
			};
		}
		case Actions.REMOVE_ID_NEW_PATIENT: {
			return {
				...state,
				idNewPatient: 0
			};
		}
		case Actions.OPEN_RESET_CONFIRM_DIALOG: {
			return {
				...state,
				openResetConfirmDialog: action.open
			};
		}
		// case Actions.GET_PRATICIEN_JOB: {
		// 	return {
		// 		dialogOpen: true,
		// 		data: action.payload
		// 	};
		// }
		// case Actions.REMOVE_CARD: {
		// 	return initialState;
		// }
		// case Actions.CLOSE_CARD_DIALOG: {
		// 	return initialState;
		// }
		default:
			return state;
	}
};

export default consultReducer;
