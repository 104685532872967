import API from '@pi/api';

export const GET_PRATICIEN_JOB = '[APP STORE] GET PRATICIEN JOB';
export const PATIENTS_DIALOG_OPEN = '[APP STORE] PATIENTS DIALOG OPEN';
export const CREATE_CONSULTATION_PATIENT = '[APP STORE] CREATE CONSULTATION PATIENT';
export const REMOVE_ID_NEW_PATIENT = '[APP STORE] REMOVE ID NEW PATIENT';
export const OPEN_RESET_CONFIRM_DIALOG = '[APP STORE] OPEN RESET CONFIRM DIALOG';

export function getPraticienJob(Iduser, idOrganization) {
	const request = API.post('/api/Job_specialist', {
		action: 'list',
		Iduser,
		idOrganization
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_PRATICIEN_JOB,
				payload: response.data
			})
		);
}

export function openConsultationBar(open) {
	return dispatch => {
		return Promise.all([
			dispatch({
				type: PATIENTS_DIALOG_OPEN,
				open: open
			})
		]);
	};
}

export function createConsultationPatient(infoPatient) {
	return (dispatch, getState) => {
		const patientCreate = {
			PhoneNumber: infoPatient.PhoneNumber ? infoPatient.PhoneNumber : '',
			FullName: infoPatient.FullName ? infoPatient.FullName : '',
			Email: infoPatient.Email ? infoPatient.Email : ''
		};

		const request = API.post('/api/patient', {
			action: 'create',
			Iduser: infoPatient.Iduser,
			idOrganization: infoPatient.idOrganization,
			patientCreate
		});

		return request.then(response => {
			return dispatch({
				type: CREATE_CONSULTATION_PATIENT,
				id: response.data.idPatient,
				payload: response.data
			});
		});
	};
}

export function removeIdPatient() {
	return dispatch => {
		return dispatch({
			type: REMOVE_ID_NEW_PATIENT
		});
	};
}

export function openResetConfirmDialog(value) {
	return dispatch => {
		return dispatch({
			type: OPEN_RESET_CONFIRM_DIALOG,
			open: value
		});
	};
}
