import React from 'react';
import { IconButton } from '@material-ui/core';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useDispatch } from 'react-redux';
import * as Actions from '../../../store/actions';

export default function ConsultationBarHeaderAction({ setIsClear, setNeedReset }) {
	//
	const dispatch = useDispatch();

	const handleClose = () => {
		setIsClear(false);
		dispatch(Actions.openConsultationBar(false));
	};

	async function handleResetAndClose() {
		await setNeedReset(true);
	}

	return (
		<div className="flex justify-end text-center">
			<IconButton variant="outlined" onClick={handleClose}>
				<ArrowDropDownCircleIcon
					sx={{
						color: 'white'
					}}
					// color="white"
					style={{
						color: 'white'
					}}
				/>
			</IconButton>
			<div className="mr-10">
				<IconButton variant="outlined" onClick={handleResetAndClose}>
					<CancelIcon
						sx={{
							color: 'white'
						}}
						// color="white"
						style={{
							color: 'white'
						}}
					/>
				</IconButton>
			</div>
		</div>
	);
}
