import React from 'react';
import { IconButton } from '@material-ui/core';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import BrandingWatermarkOutlinedIcon from '@material-ui/icons/BrandingWatermarkOutlined';

export default function ConsultationBarHeaderFullScreen({ isFullScreen, setIsFullScreen }) {
	//
	const handleFullScreen = () => {
		setIsFullScreen(previous => !previous);
	};

	return (
		<div className="flex justify-start text-center">
			<IconButton
				variant="outlined"
				onClick={handleFullScreen}
				sx={{
					color: 'white'
				}}
			>
				{isFullScreen ? (
					<BrandingWatermarkOutlinedIcon
						sx={{
							color: 'white'
						}}
						// color="white"
						style={{
							color: 'white'
						}}
					/>
				) : (
					<AspectRatioIcon
						sx={{
							color: 'white'
						}}
						// color="white"
						style={{
							color: 'white'
						}}
					/>
				)}
			</IconButton>
		</div>
	);
}
