import API from '@pi/api';
import Moment from 'moment';
import 'moment/locale/fr';
import { extendMoment } from 'moment-range';
// import { showMessage } from 'app/store/fuse/messageSlice';

export const GET_PRATICIENS = '[APP STORE] GET PRATICIEN';
export const GET_PATIENTS = '[APP STORE] GET PATIENTS';
export const GET_PATIENT = '[APP STORE] GET PATIENT';
export const SET_PATIENT = '[APP STORE]SET PATIENT';
export const GET_PRATICIEN_APPOINTMENT = '[APP STORE] GET PRATICIEN APPOINTMENT';
export const GET_TIME = '[APP STORE] GET TIME';
export const SET_TIME = '[APP STORE] SET TIME';
export const SET_DATETIME = '[APP STORE] SET DATETIME';
export const SAVE_APPOINTMENT = '[APP STORE] SAVE APPOINTMENT';
export const RESET_APPOINTMENT = '[APP STORE] RESET APPOINTMENT';
export const GET_PRATICIEN_APPOINTMENT_RESET = '[APP STORE] GET PRATICIEN APPOINTMENT RESET';
export const RESET_TIME = '[APP STORE] RESET TIME';
export const PATIENTS_DIALOG_CLOSE = '[APP STORE] PATIENTS DIALOG CLOSE';
export const UPDATE_PATIENTS = '[APP STORE] PATIENTS DIALOG CLOSE';
export const ADD_CONSULTATION = '[APP STORE] ADD CONSULTATION';
export const CREATE_PATIENT = '[APP STORE] CREATE PATIENT';
export const UPLOAD_DOCUMENTS = '[APP STORE] UPLOAD DOCUMENT';
export const UPDATE_FORM_DATA = '[APP STORE] UPDATE FORM DATA';

const moment = extendMoment(Moment);
moment.locale('fr');

export function getPraticien(Iduser, idOrganization) {
	const request = API.post('/api/user', {
		action: 'listpractitioners',
		Iduser,
		idOrganization
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_PRATICIENS,
				payload: response.data
			})
		);
}

export function getPatients(IdUser, idOrganization) {
	const request = API.post('/api/patient', {
		action: 'list',
		Iduser: IdUser,
		idOrganization
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_PATIENTS,
				payload: response.data
			})
		);
}

export function createPatient(infoId, infoPatient) {
	const patientCreate = infoPatient;

	const request = API.post('/api/patient', {
		action: 'create',
		Iduser: infoId.idUser,
		idOrganization: infoId.idOrganization,
		patientCreate
	});

	return dispatch =>
		request.then(response => {
			const patient = response.data;
			return dispatch({
				type: CREATE_PATIENT,
				id: patient.idPatient,
				payload: patient
			});
		});
}

export function updateFormData(formData) {
	return dispatch => {
		return Promise.all([
			dispatch({
				type: UPDATE_FORM_DATA,
				payload: formData
			})
		]);
	};
}

export function addConsultation(newConsultation) {
	return (dispatch, getState) => {
		const dataRequestPratician = {
			action: 'createBySpeciality',
			idPatient: newConsultation.idPatient,
			Iduser: getState().auth.user.id,
			idOrganization: getState().auth.user.idOrganization,
			idJobSpecialist: newConsultation.idJobSpecialist,
			idConsultation: newConsultation.idConsultation,
			consultationCreate: {
				ConsultationDate: newConsultation.ConsultationDate,
				ConsultationNote: newConsultation.ConsultationNote,
				Diagnostic: newConsultation.Diagnostic,
				HealthCareCategoy: newConsultation.HealthCareCategoy,
				Size: newConsultation.Size,
				Weight: newConsultation.Weight,
				antalgesicTreatment: newConsultation.antalgesicTreatment,
				dolorIntensity: newConsultation.dolorIntensity,
				dolorLocation: newConsultation.dolorLocation,
				eradiation: newConsultation.eradiation,
				prevention1: newConsultation.prevention1,
				prevention2: newConsultation.prevention2,
				prevention3: newConsultation.prevention3,
				startEradiationDate: newConsultation.startEradiationDate,
				treatmentEfficacy: newConsultation.treatmentEfficacy,
				treatmentName: newConsultation.treatmentName,
				triggeringFactor: newConsultation.triggeringFactor
			}
		};

		const dataRequestDietetique = {
			action: 'createBySpeciality',
			idPatient: newConsultation.idPatient,
			Iduser: getState().auth.user.id,
			idOrganization: getState().auth.user.idOrganization,
			idJobSpeciality: newConsultation.idJobSpecialist,
			idJobSpecialist: newConsultation.idJobSpecialist,
			idConsultation: newConsultation.idConsultation,
			consultationCreate: {
				ConsultationDate: newConsultation.ConsultationDate,
				ConsultationNote: newConsultation.ConsultationNote,
				Diagnostic: newConsultation.Diagnostic,
				HealthCareCategoy: newConsultation.HealthCareCategoy,
				Size: newConsultation.Size,
				riskyFoodsFrequency: newConsultation.riskyFoodsFrequency,
				Weight: newConsultation.Weight,
				pattern: newConsultation.pattern.food,
				physicalCapacity: newConsultation.physicalCapacity,
				breakfast: newConsultation.breakfast,
				lunch: newConsultation.lunch,
				dinner: newConsultation.dinner,
				snack: newConsultation.snack,
				dislikes: newConsultation.dislikes
			}
		};

		const request = API.post(
			'/api/consultation',
			newConsultation.idJobSpecialist === '1' ? dataRequestPratician : dataRequestDietetique
		);
		return request.then(response => {
			Promise.all([
				dispatch({
					type: ADD_CONSULTATION,
					payload: response.data
				})
			]);
		});
	};
}

export function uploadRandomDocument(idConsultation, fileInfos) {
	return (dispatch, getState) => {
		const idConsult = idConsultation;
		const idPatient = fileInfos.idPatient;
		const Iduser = fileInfos.Iduser ? fileInfos.IdUser : getState().auth.user.id;
		const patientInfo = {
			idPatient,
			Iduser: Iduser,
			idOrganization: getState().auth.user.idOrganization
		};
		if (fileInfos.uploadFiles.length > 0) {
			_.map(fileInfos.uploadFiles, async _files => {
				for (let i = 0; i < _files.length; i++) {
					const file = _files[i];

					const dataForm = new FormData();
					dataForm.append('action', 'create');
					dataForm.append('Iduser', getState().auth.user.id);
					dataForm.append('idConsultation', idConsult);
					dataForm.append('idPatient', idPatient);
					dataForm.append('idOrganization', getState().auth.user.idOrganization);
					dataForm.append('document', file);

					try {
						const request = API.post('/api/document', dataForm);
						const response = await request;

						await Promise.all([
							dispatch({
								type: UPLOAD_DOCUMENTS,
								payload: response.data
							})
						]);
					} catch (error) {
						// Gérer les erreurs ici, par exemple, enregistrer l'erreur dans un état ou afficher un message d'erreur
					}
				}
			});
		}
	};
}
