import { Redirect } from 'react-router-dom';
import React from 'react';
import { authRoles } from 'app/auth';

const ArticlesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.developer, // ['admin']
  routes: [
    {
      path: '/articles',
      component: React.lazy(() => import('./Articles')),
    },
    {
      path: '/articles/view',
      component: () => <Redirect to="/articles" />,
    },
    {
      path: '/articles/create',
      component: () => <Redirect to="/articles" />,
    },
  ],
};

export default ArticlesConfig;
