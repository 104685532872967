import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import React, { lazy } from 'react';

const TypeOfProgramsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.wellbe, // ['wellbe']
  routes: [
    {
      path: '/typeofprograms',
      component: React.lazy(() => import('./TypeOfPrograms')),
    },
  ],
};

export default TypeOfProgramsConfig;
