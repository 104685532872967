 import { authRoles } from 'app/auth';
import React from 'react'; 

const GenderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.wellbe, // ['wellbe']
  routes: [
    {
      path: '/gender',
      component: React.lazy(() => import('./Gender')),
    },
  ],
};

export default GenderConfig;
