import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import Consultations from '../Consultation';
import ConsultationBarHeader from './ConsultationBarHeader';
import * as Actions from '../../store/actions';
import ConsultationBarHeaderButton from './ConsultationBarHeaderButton';

const ConsultationBar = () => {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const app = useSelector(({ consultationGeneral }) => consultationGeneral.consultation.open);
	const [needReset, setNeedReset] = React.useState(false);

	const [isFullScreen, setIsFullScreen] = useState(false);
	const [open, setOpen] = useState(false);
	const [isClear, setIsClear] = useState(false);
	const [isAcess, setIsAcess] = useState(true);

	useEffect(() => {
		if (user && user.id) {
			dispatch(Actions.getPatients(user.id, user.idOrganization));
			dispatch(Actions.getPraticienJob(user.id, user.idOrganization));
		}
	}, [dispatch, user]);

	if (app) {
		console.log('app', app);
	}

	if (!app) {
		return <ConsultationBarHeaderButton open={app} />;
	}

	return (
		<div className={`${!isAcess ? 'hidden' : 'z-99 bottom-0 right-0 overflow-x-hidden'}`}>
			<div
				className={`fixed w-full max-w-full z-99 bottom-0 right-0 h-${isFullScreen ? 'full' : '3/4'} max-h-${
					isFullScreen ? 'full' : '3/4'
				} w-full  md:h-${isFullScreen ? 'full' : '3/4'} overflow-x-hidden md:max-h-${
					isFullScreen ? 'full' : '3/4'
				} md:w-${
					isFullScreen ? '4/5' : '1/2'
				} bg-white flex justify-center flex-col items-center  border-solid border-t-2 border-l-2 border-grey`}
			>
				<ConsultationBarHeader
					isFullScreen={isFullScreen}
					setIsFullScreen={setIsFullScreen}
					isAcess={isAcess}
					setIsAcess={setIsAcess}
					setIsClear={setIsClear}
					setNeedReset={setNeedReset}
				/>
				<div className="relative w-full m-auto h-3/4 max-h-3/4 overflow-auto">
					<Consultations
						setOpen={setOpen}
						isClear={isClear}
						setIsClear={setIsClear}
						open={app}
						consultBar={'consultBar'}
						needReset={needReset}
						setNeedReset={setNeedReset}
					/>
				</div>
				<div className="w-full flex justify-end">
					<ConsultationBarHeaderButton open={app} />
				</div>
			</div>
		</div>
	);
};

export default ConsultationBar;
