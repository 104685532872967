import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import React, { lazy } from 'react';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'dietetiques', en);
i18next.addResourceBundle('fr', 'dietetiques', fr);

const DietetiquesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.dietetiques, // ['dietetiques']
	routes: [
		{
			path: '/program/dietetiques/menu',
			component: React.lazy(() => import('./Dietetiques'))
		},
		{
			path: '/program/dietetiques',
			component: () => <Redirect to="/program/dietetiques/menu" />
		}
	]
};

export default DietetiquesConfig;
