import { lazy } from 'react';
import { authRoles } from 'app/auth';

const Statistics = lazy(() => import('./Statistics'));

const StatisticsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.stat, // [stat]
  routes: [
    {
      path: '/statistics',
      component: lazy(() => import('./Statistics'))
    //   element: <Statistics />,
    },
  ],
};

export default StatisticsConfig; 
