import i18next from 'i18next';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import React from 'react';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'dietetiques', en);
i18next.addResourceBundle('fr', 'dietetiques', fr);

const SportifProgramsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.dietetiques, // ['dietetiques']
  routes: [
    {
      path: '/program/sportif/list',
      component: React.lazy(() => import('./SportifPrograms')),
    },
    {
      path: '/program/sportif',
      component: () => <Redirect to="/program/sportif/list" />,
    },
  ],
};

export default SportifProgramsConfig;
