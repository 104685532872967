import React from 'react';
import { authRoles } from 'app/auth';

const AdminConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.wellbe,  
  routes: [
    {
      path: '/admin',
      component: React.lazy(() => import('./Administration')),
    },
  ],
};

export default AdminConfig;
