/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import FuseUtils from "@fuse/utils/FuseUtils";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Button from "@material-ui/core/Button";
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core/styles";
import { Paper, Switch, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import _ from "@lodash";
import clsx from "clsx";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UploadDragDrop from "app/shared-components/uploads/UploadDragDrop";
import moment from "moment/moment";
import DietetiqueConsultation from "./consultations/DietetiqueConsultation";
import PracticianConsultation from "./consultations/PracticianConsultation";
import * as Actions from "../store/actions";
import CKEditorConsultation from "./consultations/CKEditorConsultation";
import "moment/locale/fr";
import "./consultations/patient.css";
import ConfirmationPopUp from "./ConfirmationPopUp";
import AutoComplet from "./AutoComplet";
import AddPatientForm from "./AddPatient";

moment.locale("fr");

const useStyles = makeStyles((theme) => ({
  contentRoot: { display: "flex" },
  btnReset: { backgroundColor: "#c89d2d" },
}));

/** Form Validation Schema * */
const schema = yup.object().shape({});

const defaultValues = {
  idConsultation: FuseUtils.generateGUID(),
  idJobSpecialist: "",
  idOrganization: "",
  idPatient: "",
  Iduser: "",
  ConsultationNote: "",
  Size: "",
  Weight: "",
  Diagnostic: "",
  HealthCareCategoy: "",
  ConsultationDate: moment(new Date()).format(
    moment.HTML5_FMT.DATETIME_LOCAL_SECONDS
  ),
  uploadFiles: [],
  dolorLocation: "Localisation de la douleur",
  dolorIntensity: "Intensité de la douleur",
  eradiation: "",
  triggeringFactor: "",
  treatmentName: "",
  startEradiationDate: moment(new Date()).format("DD/MM/YYYY"),
  antalgesicTreatment: "Non renseigné",
  treatmentEfficacy: "Non renseigné",
  prevention1: "",
  prevention2: "",
  prevention3: "",
  pattern: "",
  physicalCapacity: "",
  riskyFoodsFrequency: "",
  breakfast: "",
  lunch: "",
  dinner: "",
  snack: "",
  dislikes: "",
};

function Consultations({
  idConsultationSelected,
  isClear,
  setIsClear,
  needReset = false,
  setNeedReset,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const routeParams = useParams();

  const idUser = useSelector(({ auth }) => auth.user.id);
  const idJobSpecialist = useSelector(
    ({ auth }) => auth.user.data.idJobSpecialist
  );
  const patients =
    useSelector(
      ({ consultationGeneral }) => consultationGeneral.appointment.patients
    ) ?? [];
  const formData = useSelector(
    ({ consultationGeneral }) => consultationGeneral.appointment.FormData
  );
  const idNewPatient = useSelector(
    ({ consultationGeneral }) => consultationGeneral.consultation.idNewPatient
  );
  const idOrganization = useSelector(({ auth }) => auth.user.idOrganization);
  const openResetConfirm = useSelector(
    ({ consultationGeneral }) =>
      consultationGeneral.consultation.openResetConfirmDialog
  );
  const { idPatient } = routeParams;

  const [newPatient, setNewPatient] = React.useState(false);
  const [openConfirmSave, setOpenConfirmSave] = React.useState(false);
  const [patientList, setPatientList] = React.useState([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [action, setAction] = React.useState("new");
  const [newPatientForm, setNewPatientForm] = React.useState({
    name: "",
    phone: "",
    email: "",
  });
  const [patient, setPatient] = React.useState({
    name: "",
    idPatient: "",
    phonenumber: "",
  });

  const finalSubmitIsDisabled = newPatient
    ? ((!newPatientForm.name ||
        !newPatientForm.phone ||
        !newPatientForm.email || patientList.filter((item) => item.name === newPatientForm.name).length > 0) &&
        true) ||
      false
    : !patient.name || !patient.idPatient;

  const {
    control,
    formState,
    reset,
    watch,
    handleSubmit,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmitIsDisabled = () => {
    const formValues = getValues();

    const excludedProperties = [
      "ConsultationDate",
      "dolorLocation",
      "dolorIntensity",
      "uploadFiles",
      "antalgesicTreatment",
      "idConsultation",
      "treatmentEfficacy",
      "startEradiationDate",
    ];

    const nonEmptyValues = Object.entries(formValues)
      .filter(
        ([key]) =>
          !excludedProperties.includes(key) &&
          formValues[key] !== "" &&
          !(Array.isArray(formValues[key]) && formValues[key].length === 0)
      )
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});

    const allPropertiesEmpty = Object.entries(nonEmptyValues).length === 0;

    return allPropertiesEmpty;
  };

  const { errors } = formState;
  const consultForm = watch();

  React.useEffect(() => {}, [isClear, reset]);

  React.useEffect(() => {
    if (patients && patients.length > 0) {
      const newArray = patients.map((item) => {
        return {
          name: item.FullName,
          idPatient: item.idPatient,
          phonenumber: item.PhoneNumber,
        };
      });
      setPatientList(newArray);
    }
  }, [patients]);

  React.useEffect(() => {
    if (idConsultationSelected !== null) {
      setValue("idConsultation", idConsultationSelected);
    }
  }, [idConsultationSelected]);

  React.useEffect(() => {
    if (!_.isEqual(formData, consultForm)) {
      reset({ ...formData });
    }
  }, [formData, consultForm]);

  React.useEffect(() => {
    if (needReset && !descriptionIsValid && onSubmitIsDisabled()) {
      dispatch(Actions.openConsultationBar(false));
      setNeedReset(false);
    } else if (needReset) {
      handleOpenConfirm();
    } else if (needReset && !nonEmptyValues) {
      dispatch(Actions.openResetConfirmDialog(true));
    }
  }, [needReset]);

  React.useEffect(() => {
    setAction("new");
  }, []);

  const handlePatient = (setPat) => {
    const { name, idPatient, phonenumber } = setPat;

    const getPatient = {
      name,
      idPatient,
      phonenumber,
    };
    setPatient(getPatient);
  };

  async function onSubmit(idPatient = patient.idPatient) {
    const object = {
      ...getValues(),
      idPatient: idPatient,
      idConsultation:
        getValues().idConsultation === undefined
          ? FuseUtils.generateGUID()
          : FuseUtils.generateGUID(),
      startEradiationDate:
        moment(getValues().startEradiationDate).format("YYYY-MM-DD") !==
        "Invalid date"
          ? moment(getValues().startEradiationDate).format("YYYY-MM-DD")
          : moment(new Date()).format("YYYY-MM-DD"),
      idJobSpecialist,
      ConsultationNote:
        getValues().ConsultationNote !== undefined ||
        getValues().ConsultationNote !== null
          ? getValues().ConsultationNote
          : "",
      Diagnostic:
        getValues().Diagnostic !== undefined || getValues().Diagnostic !== null
          ? getValues().Diagnostic
          : "",
      HealthCareCategoy:
        getValues().HealthCareCategoy !== undefined ||
        getValues().HealthCareCategoy
          ? getValues().HealthCareCategoy
          : "",
    };
    await dispatch(Actions.addConsultation(object));
    if (consultForm.uploadFiles && consultForm.uploadFiles.length > 0) {
      await dispatch(
        Actions.uploadRandomDocument(object.idConsultation, object)
      );
      setValue(`uploadFiles`, []);
    }
    await setSnackbarSeverity("success");
    await setSnackbarMessage("Consultation créer avec succès");
    await setOpenSnackbar(true);
    await setPatient({
      name: "",
      idPatient: "",
      phonenumber: "",
    });
    await onResetConsult();

    await dispatch(Actions.updateFormData({ ...defaultValues }));
    await setIsClear(true);
    await setOpenConfirmSave(false);
    await reset({
      ...defaultValues,
      idConsultation: FuseUtils.generateGUID(),
      idPatient: idPatient || "",
      Iduser: idUser,
    });
  }

  const onResetConsult = () => {
    setAction("new");
    reset({
      ...defaultValues,
      idConsultation: FuseUtils.generateGUID(),
      idPatient: idPatient || "",
      Iduser: idUser,
    });
  };

  async function handleOpenConfirm() {
    await dispatch(Actions.openResetConfirmDialog(true));
  }

  async function handleOpenConfirmSave() {
    await setOpenConfirmSave(true);
  }

  async function handleReset() {
    await reset({ ...defaultValues });
    await dispatch(Actions.updateFormData({ ...defaultValues }));
    await dispatch(Actions.openResetConfirmDialog(false));
    if (needReset) {
      await dispatch(Actions.openConsultationBar(false));
      await setNeedReset(false);
    }
  }

  React.useEffect(() => {}, [consultForm.uploadFiles]);

  React.useEffect(() => {
    dispatch(Actions.openResetConfirmDialog(false));
  }, []);

  const calculateIMC = (value) => {
    const weight = value.Weight;
    const size = value.Size;
    const imc = (weight * 10000) / (size * size);
    return parseFloat(imc).toFixed(2);
  };

  const descriptionIsValid =
    getValues().ConsultationNote ||
    getValues().Diagnostic ||
    getValues().HealthCareCategoy;

  const handleCancel = async () => {
    await dispatch(Actions.openResetConfirmDialog(false));
    setNeedReset(false);
  };

  async function onCreatePatient(data) {
    const eventCreate = {
      Iduser: idUser,
      idOrganization: idOrganization,
      idSpecialist: idUser,
      PhoneNumber: newPatientForm.phone ?? "",
      FullName: newPatientForm.name ?? "",
      Email: newPatientForm.email ?? "",
    };
    dispatch(Actions.createConsultationPatient(eventCreate));
  }

  React.useEffect(() => {
    if (newPatient && idNewPatient > 0) {
      onSubmit(idNewPatient);
      setNewPatient(false);
      dispatch(Actions.removeIdPatient());
      dispatch(Actions.getPatients(idUser, idOrganization));
    }
  }, [idNewPatient]);

  return (
    <form
      name="consultationForm"
      noValidate
      className="w-full flex  flex-col justify-center"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={clsx("flex pb-10 pt-2 justify-between ml-10")}>
        <Button
          variant="contained"
          color="primary"
          className={clsx(
            classes.btnReset,
            action === "edit" ? "w-224 ml-10" : "hidden"
          )}
          aria-label="Reset"
          onClick={onResetConsult}
          type="button"
        >
          Nouvelle Consultation
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenConfirm}
          sx={{ color: "white" }}
          endIcon={<RotateLeftIcon />}
        >
          Vider le formulaire
        </Button>

        <Button
          variant="contained"
          color="primary"
          className="w-100 md:w-224 mr-10"
          aria-label="Register"
          disabled={onSubmitIsDisabled()}
          onClick={handleOpenConfirmSave}
        >
          Enregistrer
        </Button>
      </div>
      <Paper
        component={motion.div}
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
        className={clsx(
          classes.contentRoot,
          "flex flex-1 p-40 items-center max-w-full w-full shadow"
        )}
        aria-label="Consultations"
      >
        <div className="flex flex-1 flex-col items-center">
          <div className="flex gap-14 pb-8">
            <Controller
              name="Size"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="size"
                  fullWidth
                  variant="filled"
                  label="Taille"
                  value={value === null ? "" : value}
                  onChange={(e) =>
                    onChange(
                      e.target.value,
                      dispatch(
                        Actions.updateFormData({
                          ...getValues(),
                          Size: e.target.value,
                        })
                      )
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">(cm)</InputAdornment>
                    ),
                  }}
                  error={!!errors.Size}
                />
              )}
            />
            <Controller
              name="Weight"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  id="weight"
                  fullWidth
                  variant="filled"
                  label="Poids"
                  value={value === null ? "" : value}
                  onChange={(e) =>
                    onChange(
                      e.target.value,
                      dispatch(
                        Actions.updateFormData({
                          ...getValues(),
                          Weight: e.target.value,
                        })
                      )
                    )
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">(Kg)</InputAdornment>
                    ),
                  }}
                  error={!!errors.Weight}
                />
              )}
            />
          </div>
          <div>
            <Typography variant="h6" className="mb-16">
              IMC : {calculateIMC(getValues())}
            </Typography>
          </div>
          {idJobSpecialist === "1" ? (
            <PracticianConsultation
              control={control}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
            />
          ) : (
            <DietetiqueConsultation
              control={control}
              watch={watch}
              getValues={getValues}
            />
          )}
          <div className="CKEditor w-full">
            <Controller
              id="scroll_marker"
              name="ConsultationNote"
              control={control}
              render={({ field }) => (
                <CKEditorConsultation
                  field={field}
                  label="Consultation"
                  name="ConsultationNote"
                  getValues={getValues}
                />
              )}
            />
          </div>
          <div className="CKEditor">
            <Controller
              className="max-w-full"
              name="Diagnostic"
              control={control}
              render={({ field }) => (
                <CKEditorConsultation
                  field={field}
                  label="Diagnostic"
                  name="Diagnostic"
                  getValues={getValues}
                />
              )}
            />
          </div>
          <div className="CKEditor">
            <Controller
              className="max-w-full"
              name="HealthCareCategoy"
              control={control}
              render={({ field }) => (
                <CKEditorConsultation
                  field={field}
                  label="Soins"
                  name="HealthCareCategoy"
                  getValues={getValues}
                />
              )}
            />
          </div>
          <div className="flex flex-col pt-8 w-full">
            <UploadDragDrop
              accept=".jpg,.png,.jpeg,.pdf,.doc,.docx"
              labelInput="Téléverser fichier(s)"
              labelPreview="Fichier(s) ajouté"
              multiple
              onChange={(files) => (
                setValue(`uploadFiles`, files, {
                  shouldDirty: true,
                  shouldValidate: true,
                }),
                dispatch(
                  Actions.updateFormData({ ...getValues(), uploadFiles: files })
                )
              )}
              uploadFiles={consultForm.uploadFiles}
            />
          </div>
        </div>
      </Paper>
      <ConfirmationPopUp
        open={openConfirmSave}
        patient={patient}
        setPatient={setPatient}
        patientList={patientList}
        setOpen={setOpenConfirmSave}
        onSubmit={(newPatient && onCreatePatient) || onSubmit}
        text="Choisissez un patient"
        confirmationLabel={
          (!newPatient && "Enregistrer") || "Créer le Patient et enregistrer"
        }
        disabled={finalSubmitIsDisabled}
        sx={{
          overflow: "visible !important",
        }}
      >
        <div
          id="autoCompletConsultation"
          className="w-full flex justify-center items-center flex-col"
        >
          <div className="w-full flex">
            <Button
              onClick={() => {
                setNewPatient(!newPatient);
              }}
              variant="contained"
              sx={{
                my: 4,
              }}
            >
              {(!newPatient && "Créer un nouveau") ||
                "Retourner à la séléction d'un"}{" "}
              patient
            </Button>
          </div>
          {(!newPatient && (
            <AutoComplet
              options={patientList.filter((item) => item.name !== "")}
              className="text-ellipsis"
              label="Choix du patient" 
              handleChange={handlePatient}
              setValue={setPatient}
              value={patient}
              sx={{
                width: "100%",
                "& .MuiAutocomplete-root": {
                  width: "100% !important",
                  maxWidth: "100% !important",
                },
                "& #autoCompletConsultation > *": {
                  width: "100% !important",
                  maxWidth: "100% !important",
                },
              }}
            />
          )) || (
            <AddPatientForm
              patient={newPatientForm}
              setPatient={setNewPatientForm}
              onSubmit={onCreatePatient}
              patients={patientList}
            />
          )}
        </div>
      </ConfirmationPopUp>
      <div className="w-full flex justify-center items-center">
        <Snackbar
          className="w-full flex justify-center items-center"
          open={openSnackbar}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbar(false)}
        >
          <MuiAlert
            onClose={() => setOpenSnackbar(false)}
            severity={snackbarSeverity}
            className="w-3/4 md:w-1/3 m-auto flex justify-center items-center"
            sx={{ fontSize: "1.5rem" }}
            elevation={6}
            variant="filled"
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      </div>
      <ConfirmationPopUp
        open={openResetConfirm}
        onCancel={handleCancel}
        onSubmit={handleReset}
        text="Êtes vous sûr de vouloir vider le formulaire ?"
        confirmationLabel="Vider le formulaire"
      />
    </form>
  );
}

export default Consultations;
