import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { authRoles } from 'app/auth';
import * as Actions from '../../store/actions';

const StyledButton = withStyles(theme => ({
	root: {
		borderRadius: 3,
		border: 0,
		height: 48,
		padding: '0 30px',
		boxShadow: 'none',
		color: theme.palette.getContrastText(theme.palette.common.white),
		backgroundColor: '#243a66ed',
		'&:hover': {
			backgroundColor: theme.palette.background.default,
			color: '#253b67',
			border: `2px solid #253b67`
		}
	}
}))(Button);

export default function ConsultationBarHeaderButton({ open }) {
	const dispatch = useDispatch();
	const user = useSelector(({ auth }) => auth.user);
	const userRole = useSelector(({ auth }) => auth.user.role);
	const [isAcess, setIsAcess] = useState(true);

	useEffect(() => {
		if (!_.isEmpty(user) && !_.includes(authRoles.dps, userRole)) {
			setIsAcess(false);
		} else {
			setIsAcess(true);
		}
	}, [isAcess, user, userRole]);

	return (
		<div
			className={`${
				!isAcess
					? 'hidden'
					: 'fixed z-99 bottom-0 right-0 overflow-x-hidden flex justify-center flex-col items-end cursor-pointer'
			}`}
		>
			<StyledButton
				onClick={() => dispatch(Actions.openConsultationBar(!open))}
				className="font-bold text-md text-white text-centerbottom-0 right-0 h-48 w-100 flex min-w-100 md:w-150 md:min-w-150 justify-end items-center cursor-pointer text-center"
			>
				Consultation
			</StyledButton>
		</div>
	);
}
