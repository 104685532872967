import { Redirect } from 'react-router-dom';
import React from 'react';
import { authRoles } from 'app/auth';

const SportifProgramsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.developer, // ['admin']
  routes: [
    {
      path: '/newspapers',
      component: React.lazy(() => import('./Newspapers')),
    },
    {
      path: '/newspapers/view',
      component: () => <Redirect to="/newspapers" />,
    },
    {
      path: '/newspapers/create',
      component: () => <Redirect to="/newspapers" />,
    },
  ],
};

export default SportifProgramsConfig;
