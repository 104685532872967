const locale = {
  APPLICATIONS: 'Applications',
  DASHBOARDS: 'Tableau de Bord',
  APPOINTMENT: 'Rendez-Vous',
  AGENDA: 'Agenda',
  USERS: 'Utilisateurs',
  DIETETIQUE: 'Diététiques',
  PATIENTS: 'Dossiers patients',
  MESSAGEPATIENTS: 'Messagerie',
  LOGIN: 'Login',
  WELLBESETTING: "Paramètres WellBe",
  EXAMPLE: 'Example',
};

export default locale;
