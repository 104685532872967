import TextField from "@material-ui/core/TextField";
import moment from "moment";
import "moment/locale/fr";
import _ from "@lodash";
import React from "react";
import { Box, Typography } from "@material-ui/core";

moment.locale("fr");

export default function AddPatientForm({
  patient,
  setPatient,
  errors = {
    name: false,
    email: false,
  },
  patients,
}) {
  const sameName =
    patient.name !== "" &&
    patients.filter((item) => item.name === patient.name).length > 0;
  const samePhoneNumber =
    patient.phone !== "" &&
    patients.filter((item) => item.phonenumber === patient.phone).length > 0;
  const handleChange = (event) => {
    setPatient({ ...patient, [event.target.name]: event.target.value });
  };
  return (
    <>
      <TextField
        label="Nom du patient"
        className="mt-8"
        name="name"
        margin="dense"
        value={patient.name}
        onChange={handleChange}
        error={!!errors.name}
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        autoFocus
        required
        fullWidth
      />
      {sameName && (
        <Typography variant="body2" color="error">
          Un patient avec ce nom existe déja
        </Typography>
      )}
      <Box className=" mb-16" />
      <TextField
        id="phone"
        label="Téléphone"
        className="mt-8 mb-16"
        name="phone"
        margin="dense"
        value={patient.phone}
        variant="outlined"
        onChange={handleChange}
        fullWidth
      />
      {samePhoneNumber && (
        <Typography variant="body2" color="error">
          Un patient avec ce numéro de téléphone existe déja
        </Typography>
      )}
      <TextField
        id="email"
        label="Adresse Email"
        name="email"
        type="email"
        value={patient.email}
        margin="dense"
        variant="outlined"
        onChange={handleChange}
        fullWidth
        required
        error={!!errors.email}
      />
    </>
  );
}
