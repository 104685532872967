import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export default function ConfirmationPopUp({
	open,
	setOpen,
	onSubmit,
	text = 'Etes vous sur de vouloir supprimer cet item ? Ce choix est irréversible.',
	confirmationLabel = 'Supprimer',
	colorLabel = 'warning',
	children,
	onCancel = () => {},
	disabled = false,
	sx = {}
}) {
	const handleClose = () => {
		setOpen && setOpen(false);
		onCancel();
	};

	const handleConfirm = () => {
		onSubmit();
		setOpen && setOpen(false);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			disableEnforceFocus
			sx={{
				'& .MuiDialog-paper': {
					width: '100%',
					maxWidth: 600,
					...sx
				}
			}}
		>
			<DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{text}</DialogContentText>
				{children}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Annuler</Button>
				<Button autoFocus color={colorLabel} onClick={handleConfirm} disabled={disabled}>
					{confirmationLabel}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
